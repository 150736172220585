/* Global Variables */
$roboto: "Roboto", sans-serif;
$open-sans: "Open Sans", sans-serif;
$montserrat: "Montserrat", sans-serif;
$purple-rain: #694489;

@import "components/color-variables";
$primary-color: color("green", "lighten-1");

// Import this last to override variables
@import "materialize";

/* Global Styles */
html {
    font-family: $montserrat;
}

p {
    font-family: $open-sans;
    font-size: 1.2em;
}

section {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

h6 {
    font-size: 1.2em;
}
.sublabel {
    color: black;
    margin-bottom: 5px;
}
.label {
    font-family: $montserrat;
    font-weight: 500;
    color: #66bb6a;
}
p.info {
    color: #6b7c93;
    font-size: 1.1rem;
}
.info b {
    color: #66bb6a;
}
.subtitle {
    width: 80%;
    margin: auto;
}

.header {
    margin-bottom: 24px;
}

a:hover {
    text-decoration: underline;
}
