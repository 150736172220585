@import "theme";

.bio {
    padding-left: 40px;
    vertical-align: top;
}

.image-container {
    width: 15em;
    height: 15em;
    overflow: hidden;
}

.image {
    width: 100%;
}
