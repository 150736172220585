@import "theme";
@import "components/color-variables";
@import "components/variables";

nav {
    background-color: color("grey", "lighten-3");

    a.brand-logo {
        font-family: $montserrat;
        font-weight: 700;
        color: $primary-color;
    }

    a.button-collapse i {
        margin-left: 0.5em;
    }

    ul {
        a {
            font-family: $open-sans;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: black;
        }
    }

    ul.side-nav {
        a {
            font-weight: 600;
            color: black;
        }
    }
}

// https://stackoverflow.com/questions/38642911/
.navbar-fixed {
    z-index: 999;
}

@media #{$large-and-up} {
    .nav-wrapper {
        padding-left: 2em;
        padding-right: 2em;
    }
}
